import { Modal, ModalProps } from 'antd'
import styled from 'styled-components'
import * as React from 'react'
import NiceModal, { NiceModalHandler, useModal } from '@ebay/nice-modal-react'

type IGenericModalProps = ModalProps & {
  children?: React.ReactNode
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    z-index: 99;
    top: 4%;
    right: 2%;
  }
  .ant-modal-close-x {
    font-size: 16px;
  }
`

export const GenericModalComponent: React.FC<IGenericModalProps> = ({
  children,
  ...props
}) => {
  return (
    <StyledModal {...props} footer={null} title={null}>
      {children}
    </StyledModal>
  )
}

export const GenericModalContext = React.createContext<
  Partial<NiceModalHandler>
>({})
const GenericModal = NiceModal.create((props: IGenericModalProps) => {
  const modal = useModal()
  return (
    <StyledModal
      {...props}
      footer={null}
      title={null}
      visible={modal.visible}
      onCancel={(e) => {
        if (props.onCancel) {
          props.onCancel(e)
        }
        modal.hide()
      }}
    >
      {props.children}
    </StyledModal>
  )
})

export default GenericModal
