import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { useMemo } from 'react'
import { authStateCommon } from '@pig-common/recoils'
import { useRecoilValue } from 'recoil'
import MiniToast from './miniToast'
import AnnouncementToast from './announcementToast'
import useAnnouncement from '@pig-common/hooks/useAnnouncement'

const AnnouncementContainer = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  color: white;
  user-select: none;
  transition: 3s;
`

const Announcement = ({ show }: { show: boolean }) => {
  const { customerCode } = useRecoilValue(authStateCommon.authState)
  const { announcementList, miniToast, closeAnnouncement, closeMiniToast } =
    useAnnouncement({ customerCode: `${customerCode}` })

  const renderAnnouncement = useMemo(() => {
    if (show) {
      return (
        <AnimatePresence>
          {announcementList.map((announcement) => (
            <AnnouncementToast
              key={announcement.code}
              announcement={announcement}
              onCloseToast={closeAnnouncement}
            />
          ))}
        </AnimatePresence>
      )
    }
    return null
  }, [announcementList])
  const renderMiniToast = useMemo(() => {
    return (
      <AnimatePresence mode="popLayout">
        {miniToast.map((toast) => (
          <MiniToast
            key={toast.id}
            toast={toast}
            onClick={() => closeMiniToast(toast.id)}
          />
        ))}
      </AnimatePresence>
    )
  }, [miniToast])
  return (
    <AnnouncementContainer>
      {renderAnnouncement}
      {renderMiniToast}
    </AnnouncementContainer>
  )
}

export default Announcement
