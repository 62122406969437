import dayjs, { Dayjs } from 'dayjs'

export enum ANNOUNCEMENT_TYPE {
  DEFAULT = 'NORMAL',
  ADVERTISE = 'ADVERTISE',
  EMERGENCY = 'EMERGENCY',
}
interface AnnouncementRaw {
  announcement_code: string
  amount: unknown
  date_time: string
  icon: string
  inbox_type: string
  notification_type: string
  is_read: boolean
  message: string
  subject: string
  subtitle: string
}
export type AnnouncementItem = {
  code: string
  type: ANNOUNCEMENT_TYPE
  isRead: boolean
  content: string
  subtitle?: string
  amount?: number | null
  icon?: string
  date?: Dayjs
}

const mapAnnouncement = (data: AnnouncementRaw): AnnouncementItem => {
  const {
    announcement_code,
    amount,
    date_time,
    icon,
    notification_type,
    is_read,
    message,
    subtitle,
  } = data
  return {
    code: announcement_code,
    type:
      notification_type === ANNOUNCEMENT_TYPE.EMERGENCY ||
      notification_type === ANNOUNCEMENT_TYPE.ADVERTISE
        ? notification_type
        : ANNOUNCEMENT_TYPE.DEFAULT,
    subtitle,
    icon,
    date: dayjs(date_time),
    content: message,
    amount:
      amount && typeof amount === 'number'
        ? amount
        : typeof amount === 'string'
        ? Number(amount)
        : null,
    isRead: is_read,
  }
}

export default mapAnnouncement
