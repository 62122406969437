import { ReactNode, useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

export type CommonModalProps = {
  open: boolean
  closableBackDrop?: boolean
  columnClass?: string
  scrollableContainerSelector?: string | null
  children?: ReactNode
  onClose: () => void
}

const modalVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const modalWrapperVariants = {
  initial: { opacity: 0, scale: 0.75 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 1 },
}

const CommonModal = ({
  open,
  closableBackDrop = true,
  columnClass = '',
  scrollableContainerSelector = null,
  children,
  onClose,
}: CommonModalProps): JSX.Element | null => {
  const [isBrowser, setIsBrowser] = useState(false)
  const modalWrapperRef = useRef<HTMLDivElement>(null)

  const handleClickBackdrop = (event: MouseEvent) => {
    const targetNode = event.target as Node
    if (closableBackDrop && targetNode?.contains(modalWrapperRef.current)) {
      onClose()
    }
  }

  const openModal = () => {
    if (scrollableContainerSelector && scrollableContainerSelector !== '') {
      const scrollableContainer = document.querySelector(
        scrollableContainerSelector,
      ) as Element
      disablePageScroll(scrollableContainer)
    } else {
      disablePageScroll()
    }

    window.addEventListener('click', handleClickBackdrop)
  }

  const closeModal = () => {
    if (scrollableContainerSelector && scrollableContainerSelector !== '') {
      const scrollableContainer = document.querySelector(
        scrollableContainerSelector,
      ) as Element
      enablePageScroll(scrollableContainer)
    } else {
      enablePageScroll()
    }

    window.removeEventListener('click', handleClickBackdrop)
  }

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    if (open) {
      openModal()
    } else {
      closeModal()
    }

    return () => closeModal()
  }, [open])

  if (isBrowser) {
    const modalRoot = document.getElementById('modal-root') as HTMLElement
    const modalClassNames = classNames('ps-modal', { 'ps-modal--open': open })
    const modalContent = open ? (
      <motion.div
        key="modal-wrap-motion"
        className={modalClassNames}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={modalVariants}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className={`col-xs-12 col-sm-12 col-md-6 ${columnClass}`}>
              <motion.div
                key="modal-wrap-motion"
                className="ps-modal__wrapper"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={modalWrapperVariants}
                ref={modalWrapperRef}
              >
                {children}
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    ) : null

    return createPortal(
      <AnimatePresence exitBeforeEnter>{modalContent}</AnimatePresence>,
      modalRoot,
    )
  }

  return null
}

export default CommonModal
