import mapAnnouncement, {
  AnnouncementItem,
  ANNOUNCEMENT_TYPE,
} from '@pig-common/mappers/mapToastAnnouncement'
import mapToastErrorState from '@pig-common/mappers/mapToastErrorState'
import { ERROR_STATUS_VALUE } from '@pig-common/recoils/modules/system-status'
import { getAnnouncement } from '@pig-common/services/systemStatus/getSystemStatus'
import { useQuery } from '@tanstack/react-query'
import { useState, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { useRecoilState } from 'recoil'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import miniToastState, {
  TOAST_TYPE,
  ToastMessage,
} from '@pig-common/recoils/modules/mini-toast'

const TOAST_LIFETIME = 3000
const useAnnouncement = ({
  customerCode,
}: {
  customerCode: string
}): {
  announcementList: AnnouncementItem[]
  miniToast: ToastMessage[]
  closeAnnouncement: (annoucement: AnnouncementItem) => void
  addMiniToast: (message: string, type?: TOAST_TYPE) => void
  closeMiniToast: (messageId: string) => void
} => {
  const [cookies, setCookies] = useCookies<string>([
    COOKIES_KEY.ANNOUNCEMENT_READED,
  ])
  const [miniToast, setMiniToast] = useRecoilState(miniToastState)
  const [emergencyHidden, setEmergencyHiden] = useState<string[]>([])
  const isAuth = !!customerCode
  const { data: dataAnnouncement = [] } = useQuery<AnnouncementItem[]>(
    ['announcement'],
    async () => {
      if (!isAuth) return []
      const result = await getAnnouncement({ isAuth })
      if (result === null) return []
      const errorState = mapToastErrorState({
        systemStatus: result?.systemStatus,
        isAuthen: isAuth,
      })
      const {
        isSCBUser,
        freeStyleSCB,
        freeStyleTextSCB,
        freeStyle,
        freeStyleText,
      } = result.systemStatus
      const generateEmergencyAnnouncement = (): AnnouncementItem => {
        let errorContent
        if (isSCBUser && freeStyleSCB && freeStyleTextSCB?.trim()) {
          // SCB user announcements
          errorContent = freeStyleTextSCB?.trim()
        } else if (freeStyle && freeStyleText?.trim()) {
          // global announcements
          errorContent =
            freeStyle && freeStyleText?.trim() ? freeStyleText?.trim() : ''
        } else if (isAuth) {
          // registered user
          switch (errorState) {
            case ERROR_STATUS_VALUE.NONE:
              errorContent = ''
              break
            case ERROR_STATUS_VALUE.DEPOSIT:
              errorContent = 'ขณะนี้ระบบเติมเงินขัดข้อง'
              break
            case ERROR_STATUS_VALUE.WITHDRAW:
              errorContent = 'ขณะนี้ระบบถอนเงินขัดข้อง'
              break
            case ERROR_STATUS_VALUE.GAME:
              errorContent = 'ขณะนี้แอป PIGSPIN ขัดข้อง'
              break
            case ERROR_STATUS_VALUE.FRIEND_INVITE:
              errorContent = 'ขณะนี้ระบบแนะนำเพื่อนขัดข้อง"'
              break
            case ERROR_STATUS_VALUE.DEPOSIT_AND_WITHDRAW:
              errorContent = 'ขณะนี้ระบบเติมเงิน และถอนเงินขัดข้อง'
              break
            case ERROR_STATUS_VALUE.DEPOSIT_AND_GAME:
              errorContent = 'ขณะนี้ระบบเติมเงิน และแอป PIGBET ขัดข้อง'
              break
            case ERROR_STATUS_VALUE.WITHDRAW_AND_GAME:
              errorContent = 'ขณะนี้ระบบถอนเงิน และแอป PIGBET ขัดข้อง'
              break
            case ERROR_STATUS_VALUE.ALL:
              errorContent = 'ขณะนี้ระบบเติมเงิน ถอนเงิน และแอป PIGBET ขัดข้อง'
              break
            case ERROR_STATUS_VALUE.SCB_DEPOSIT:
              errorContent =
                'กรุณาแจ้งเติมหลังโอนเงิน โดยส่งสลิปพร้อมแจ้ง User ผ่าน LINE หรือ Messenger'
              break
            case ERROR_STATUS_VALUE.SCB_DEPOSIT_AND_WITHDRAW:
              errorContent =
                'กรุณาแจ้งเติมหลังโอนเงิน โดยส่งสลิปพร้อมแจ้ง User และขณะนี้ระบบถอนเงินขัดข้อง'
              break
            case ERROR_STATUS_VALUE.SCB_DEPOSIT_AND_GAME:
              errorContent =
                'กรุณาแจ้งเติมหลังโอนเงิน โดยส่งสลิปพร้อมแจ้ง User และขณะนี้แอป PIGBET ขัดข้อง'
              break
            case ERROR_STATUS_VALUE.SCB_DEPOSIT_AND_WITHDRAW_AND_GAME:
              errorContent =
                'กรุณาแจ้งเติมหลังโอนเงิน โดยส่งสลิปพร้อมแจ้ง User และขณะนี้ระบบถอนเงิน และแอป PIGBET ขัดข้อง'
              break
            default:
              errorContent = ''
          }
        }
        return {
          code: 'err0r',
          type: ANNOUNCEMENT_TYPE.EMERGENCY,
          isRead: false,
          content: errorContent
            ? `<h5>${errorContent}</h5>`
            : '<h5>มีบางอย่างผิดพลาด</h5>',
        }
      }
      const mappedAnnouncement = result.announcement.map(mapAnnouncement)
      if (errorState > 1)
        mappedAnnouncement.push(generateEmergencyAnnouncement())
      const sortedAnnouncement = mappedAnnouncement.sort(
        (a: AnnouncementItem, b: AnnouncementItem) => {
          if (a.type === ANNOUNCEMENT_TYPE.EMERGENCY) return -1
          if (a.type === ANNOUNCEMENT_TYPE.ADVERTISE)
            return b.type === ANNOUNCEMENT_TYPE.DEFAULT ? -1 : 0
          return 1
        },
      )
      return sortedAnnouncement
    },
    {
      enabled: true,
    },
  )
  const announcementList = useMemo(() => {
    return dataAnnouncement.filter((annoucement: AnnouncementItem) => {
      switch (annoucement.type) {
        case ANNOUNCEMENT_TYPE.EMERGENCY:
          return !emergencyHidden.includes(annoucement.code)
        case ANNOUNCEMENT_TYPE.ADVERTISE:
        case ANNOUNCEMENT_TYPE.DEFAULT:
        default:
          return !(
            customerCode &&
            cookies[COOKIES_KEY.ANNOUNCEMENT_READED]?.[customerCode] &&
            cookies[COOKIES_KEY.ANNOUNCEMENT_READED]?.[customerCode].includes(
              annoucement.code,
            )
          )
      }
    })
  }, [
    dataAnnouncement,
    emergencyHidden,
    cookies[COOKIES_KEY.ANNOUNCEMENT_READED]?.[customerCode],
  ])

  const removeToastById = (toastId: string) => {
    setMiniToast((prev) => {
      const index = prev.findIndex((toast) => toast.id === toastId)
      if (index === -1) return prev
      const newToasts = [...prev]
      newToasts.splice(index, 1)
      return newToasts
    })
  }
  const addMiniToast = (message: string, type?: TOAST_TYPE) => {
    const newToast: ToastMessage = {
      id: `${Date.now()}`,
      message: `${message}`,
      createdAt: `${Date.now()}`,
      type: type || TOAST_TYPE.DEFAULT,
    }
    setMiniToast((prev) => [...prev, newToast])
    setTimeout(() => {
      removeToastById(newToast.id)
    }, TOAST_LIFETIME)
  }
  const closeAnnouncement = (annoucement: AnnouncementItem) => {
    if (annoucement.type === ANNOUNCEMENT_TYPE.EMERGENCY) {
      setEmergencyHiden((prev) => [...prev, annoucement.code])
    } else if (customerCode && annoucement.code) {
      const readed =
        cookies[COOKIES_KEY.ANNOUNCEMENT_READED]?.[customerCode] || []
      const now = new Date()
      const nextMidnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        0,
        0,
        0,
      )
      setCookies(
        COOKIES_KEY.ANNOUNCEMENT_READED,
        { [customerCode]: [...readed, annoucement.code] },
        {
          expires: nextMidnight,
        },
      )
    }
  }
  return {
    announcementList,
    miniToast: [...miniToast].reverse(),
    closeAnnouncement,
    addMiniToast,
    closeMiniToast: removeToastById,
  }
}

export default useAnnouncement
