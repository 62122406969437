import React, { ReactNode } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  LINE_CONTACT_URL,
  FACEBOOK_OFFICIAL_URL,
  PRODUCT_EMAIL,
  TWITTER_OFFICIAL_URL,
} from '@pig-common/models/buildtime-constant'
import CommonAlertModal from '../CommonAlertModal/CommonAlertModal'
import ContactUs from '@pig-casino/components/contact-us'

export type AlertContactLinksProps = {
  line: string
  facebook: string
  twitter: string
  email: string
}

export type AlertContactModalProps = {
  open: boolean
  header?: ReactNode
  content?: ReactNode
  contactLinks?: AlertContactLinksProps
  redirectToOnClose?: string
  closableBackDrop?: boolean
  onClose: () => void
  isShowIcon?: boolean
  columnClass?: string
  isLock?: boolean
}

const AlertContactModal = ({
  open,
  header,
  content,
  contactLinks = {
    line: LINE_CONTACT_URL,
    facebook: FACEBOOK_OFFICIAL_URL,
    twitter: TWITTER_OFFICIAL_URL,
    email: PRODUCT_EMAIL,
  },
  closableBackDrop = true,
  onClose,
  isShowIcon = true,
  columnClass = '',
  isLock = false,
  redirectToOnClose,
}: AlertContactModalProps): JSX.Element => {
  return (
    <CommonAlertModal
      id="alert-contact-modal"
      open={open}
      modalComponent="antd"
      header={header || (isLock ? 'บัญชีของท่านถูกล็อค' : 'กรุณาติดต่อแอดมิน')}
      footer={<ContactUs />}
      closableBackDrop={closableBackDrop}
      onClose={onClose}
      isShowIcon={isShowIcon}
      columnClass={columnClass}
      redirectToOnClose={redirectToOnClose}
      closeButton
    >
      {content || 'กรุณาติดต่อเจ้าหน้าที่เพื่อรับความช่วยเหลือ'}
    </CommonAlertModal>
  )
}

export const AlertContactModalV2 = NiceModal.create(
  (props: Partial<AlertContactModalProps>) => {
    const modal = useModal()
    return (
      <AlertContactModal
        {...props}
        open={modal.visible}
        onClose={() => {
          if (props.onClose) {
            props.onClose()
          }
          modal.hide()
        }}
      />
    )
  },
)

export default AlertContactModal
