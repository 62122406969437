import { ReactNode } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Link from 'next/link'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'
import { autoLineBreakerWithBr } from '@pig-common/utils/text-operation'
import { ALERT_TYPE } from '../../Alert'
import AlertContactModal from '../AlertContactModal/AlertContactModal'
import parse from 'html-react-parser'
import CommonAlertModal, {
  CommonAlertModalProps,
} from '../CommonAlertModal/CommonAlertModal'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'

export enum ALERT_MODAL_TYPE {
  ACCOUNT_DISABLED = 'DISABLED',
  BANK_ACCOUNT_DUPLICATE = 'bank-account-duplicate',
  BANK_ACCOUNT_MISMATCH_WITH_NAME = 'bank-account-mismatch-with-name',
  PIN_LOCKED = 'pin-locked',
  TOO_MANY_OTP_REQUEST = 'too-many-otp-request',
  TOO_MANY_OTP_REQUEST_OR_ATTEMPT = 'too-many-otp-request-or-attempt',
  TOO_MANY_OTP_ATTEMPT = 'too-many-otp-attempt',
  UNEXPECTED_BEHAVIORS = 'unexpected-behaviors',
  CLOSE_REGISTER = 'close-register',
  OTP_SENT = 'otp-sent',
  RESET_PIN_CHECK = 'reset-pin-check',
  KYC_ALREADY_EXIST_CID = 'kyc-already-exist-cid',
  KYC_INVALID_CID = 'kyc-invalid-cid',
  KYC_INVALID_LASER_ID = 'kyc-invalid-laser-id',
  KYC_INVALID_INFO = 'kyc-invalid-info',
  DUPLICATED_ACCOUNT = 'duplicated-account',
  DATA_NOT_FOUND = 'data-not-found',
  CONTACT_US = 'contact-us',
  CONFIRM_PIN_DOES_NOT_MATCH = 'confirm-pin-does-not-match',
  BANNED_USER = 'banned-user',
  LOCKED_ACCOUNT = 'lock-account',
  SOMETHING_WENT_WRONG = 'something-went-wrong',
  CHANGE_PIN_SUCCESS = 'change-pin-success',
  CASH_BALANCE_NOT_ENOUGH = 'cash-balance-not-enough',
  REQUIRE_PLAY_GAME = 'require-play-game',
  PHONE_DUPLICATE = 'phone-duplicate',
  EXPIRED_CARD = 'expired-card',
  PHYSICAL_REWARD_REDEEM_ERROR = 'physical-reward-redeem-error',
  WALLET_REVOKED = 'wallet-revoked',
  WALLET_CANCELED = 'wallet-canceled',
  REFERRER_NOT_VALID = 'referrer-not-valid',
  CLOSE_WITHDRAW = 'close-withdraw',
}

export type AlertModalProps = {
  type: ALERT_MODAL_TYPE
  open: boolean
  data?: any
  onClose: () => void
  columnClass?: string
  redirectToOnClose?: string
}

type Payload = {
  type: ALERT_MODAL_TYPE
  onClose: () => void
  data: any
  systemStatus: any
}

const getAlertProps = ({
  type,
  onClose,
  data,
  systemStatus,
}: Payload): Partial<CommonAlertModalProps> => {
  const contentChildren = (content: ReactNode) => <p>{content}</p>

  const footer = (
    <div className="d-flex justify-content-end">
      <Button
        type={BUTTON_TYPE.PLAIN_TEXT}
        size={BUTTON_SIZE.LG}
        onClick={onClose}
        className="p-1 lh-1 ps-text-primary-1"
      >
        ตกลง
      </Button>
    </div>
  )

  const props = (
    alertType: ALERT_TYPE,
    header: ReactNode,
    content: ReactNode,
    redirectToOnClose?: string,
  ): any => ({
    type: alertType,
    header,
    footer,
    children: contentChildren(content),
    redirectToOnClose,
  })

  switch (type) {
    case ALERT_MODAL_TYPE.ACCOUNT_DISABLED:
      return props(
        ALERT_TYPE.ERROR,
        'ไม่สามารถสมัครสมาชิกใหม่ได้',
        'กรุณาติดต่อเจ้าหน้าที่เพื่อรับความช่วยเหลือ',
      )
    case ALERT_MODAL_TYPE.BANK_ACCOUNT_DUPLICATE:
      return props(
        ALERT_TYPE.ERROR,
        'หมายเลขที่บัญชีนี้ถูกใช้แล้ว',
        'กรุณาใช้เลขที่บัญชีอื่น',
      )
    case ALERT_MODAL_TYPE.BANK_ACCOUNT_MISMATCH_WITH_NAME:
      return props(
        ALERT_TYPE.ERROR,
        'ชื่อบัญชีไม่ตรงกับชื่อบนบัตรประชาชน',
        'กรุณาตรวจสอบและลองอีกครั้ง',
      )
    case ALERT_MODAL_TYPE.PIN_LOCKED:
      return props(
        ALERT_TYPE.ERROR,
        'รหัส PIN ไม่ถูกต้อง',
        'กรุณายืนยันตัวตนเพื่อแก้ไข PIN',
      )
    case ALERT_MODAL_TYPE.TOO_MANY_OTP_REQUEST:
      return props(
        ALERT_TYPE.ERROR,
        'คุณขอรับรหัส OTP มากเกินไป',
        'กรุณาทำรายการใหม่อีกครั้งในภายหลัง',
      )
    case ALERT_MODAL_TYPE.TOO_MANY_OTP_REQUEST_OR_ATTEMPT:
      return props(
        ALERT_TYPE.ERROR,
        'คุณขอรับรหัส OTP หรือทำรายการไม่ถูกต้องมากเกินไป',
        'กรุณาทำรายการใหม่อีกครั้งในภายหลัง',
      )
    case ALERT_MODAL_TYPE.TOO_MANY_OTP_ATTEMPT:
      return props(
        ALERT_TYPE.ERROR,
        'คุณขอรับรหัส OTP หรือทำรายการไม่ถูกต้องมากเกินไป',
        'กรุณาทำรายการใหม่อีกครั้งในภายหลัง',
      )
    case ALERT_MODAL_TYPE.CLOSE_REGISTER:
      return props(ALERT_TYPE.ERROR, null, parse(systemStatus?.registerText))
    case ALERT_MODAL_TYPE.OTP_SENT:
      return props(
        ALERT_TYPE.NONE,
        'ส่งรหัส OTP เรียบร้อยแล้ว',
        <>
          {autoLineBreakerWithBr(
            'กรุณาตรวจสอบและดำเนินการต่อ หากคุณไม่ได้รับ OTP ',
          )}
          <Link href="/contact-us">กรุณาติดต่อแอดมิน</Link>
        </>,
      )
    case ALERT_MODAL_TYPE.RESET_PIN_CHECK:
      return props(
        ALERT_TYPE.NONE,
        'ข้อมูลยืนยันตัวตนไม่ถูกต้อง',
        'กรุณากรอกข้อมูลให้ถูกต้องและลองอีกครั้ง',
      )
    case ALERT_MODAL_TYPE.KYC_ALREADY_EXIST_CID:
      return props(
        ALERT_TYPE.ERROR,
        'หมายเลขบัตรประชาชนนี้ถูกใช้แล้ว',
        'กรุณาใช้เลขบัตรประชาชนอื่น',
      )
    case ALERT_MODAL_TYPE.KYC_INVALID_CID:
      return props(
        ALERT_TYPE.ERROR,
        'หมายเลขบัตรประชาชนไม่ถูกต้อง',
        'กรุณาตรวจสอบและลองอีกครั้ง',
      )
    case ALERT_MODAL_TYPE.KYC_INVALID_LASER_ID:
      return props(
        ALERT_TYPE.ERROR,
        'หมายเลขหลังบัตรประชาชนไม่ถูกต้อง',
        'กรุณาตรวจสอบและลองอีกครั้ง',
      )
    case ALERT_MODAL_TYPE.KYC_INVALID_INFO:
      return props(
        ALERT_TYPE.ERROR,
        'ชื่อ นามสกุล หรือวันเกิดไม่ถูกต้อง',
        'กรุณาตรวจสอบและลองอีกครั้ง',
      )
    case ALERT_MODAL_TYPE.CONFIRM_PIN_DOES_NOT_MATCH:
      return props(
        ALERT_TYPE.ERROR,
        'รหัส PIN ไม่ตรงกัน',
        'กรุณาใส่ PIN ใหม่อีกครั้ง',
      )
    case ALERT_MODAL_TYPE.SOMETHING_WENT_WRONG:
      return props(
        ALERT_TYPE.ERROR,
        'มีบางอย่างผิดพลาด',
        'กรุณาทำรายการใหม่อีกครั้ง',
      )
    case ALERT_MODAL_TYPE.CHANGE_PIN_SUCCESS:
      return props(
        ALERT_TYPE.SUCCESS,
        'เปลี่ยนรหัส PIN สำเร็จ',
        'ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว',
      )
    case ALERT_MODAL_TYPE.CASH_BALANCE_NOT_ENOUGH:
      return props(
        ALERT_TYPE.ERROR,
        'ยอดเงินของท่านไม่พอสำหรับการถอน',
        'กรุณากรอกจำนวนยอดเงินใหม่อีกครั้ง',
      )
    case ALERT_MODAL_TYPE.DATA_NOT_FOUND:
      return props(
        ALERT_TYPE.ERROR,
        'ไม่พบข้อมูลในระบบ',
        'กรุณากรอกข้อมูลให้ถูกต้อง',
      )
    case ALERT_MODAL_TYPE.REQUIRE_PLAY_GAME:
      return props(
        ALERT_TYPE.ERROR,
        'ถอนเงินไม่สำเร็จ',
        'คุณจะต้องเล่นเกมอย่างน้อย 1 เกม จึงจะถอนเงินได้',
      )
    case ALERT_MODAL_TYPE.PHONE_DUPLICATE:
      return props(
        ALERT_TYPE.ERROR,
        'มีเบอร์นี้ในระบบแล้ว',
        'กรุณาผูกเบอร์ใหม่',
      )
    case ALERT_MODAL_TYPE.EXPIRED_CARD:
      return props(
        ALERT_TYPE.ERROR,
        'บัตรประชาชนหมดอายุ',
        'กรุณาตรวจสอบและลองอีกครั้ง',
      )
    case ALERT_MODAL_TYPE.PHYSICAL_REWARD_REDEEM_ERROR:
      return props(ALERT_TYPE.ERROR, data.header, data.content)
    case ALERT_MODAL_TYPE.CLOSE_WITHDRAW:
      return props(
        ALERT_TYPE.ERROR,
        'ทำการปรับปรุงระบบชั่วคราว',
        'กรุณาทำรายการใหม่ภายหลัง',
      )
    case ALERT_MODAL_TYPE.REFERRER_NOT_VALID:
      return props(
        ALERT_TYPE.NONE,
        'รหัสผู้แนะนำไม่ถูกต้อง',
        'ท่านยังดำเนินการสมัครสมาชิกต่อได้โดยไม่มีรหัสผู้แนะนำ',
      )
    default:
      throw new Error('Error! invalid alert modal type')
  }
}

const AlertModal = ({
  type,
  open,
  data = {},
  onClose,
  columnClass = '',
  redirectToOnClose,
}: AlertModalProps): JSX.Element => {
  const { data: systemStatus } = useSystemStatus({ enabled: false })

  if (type === ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS) {
    return (
      <AlertContactModal
        open={open}
        header="มีบางอย่างผิดพลาด"
        content="กรุณาลองใหม่หรือติดต่อเจ้าหน้าที่"
        onClose={onClose}
        redirectToOnClose={redirectToOnClose}
      />
    )
  }

  if (type === ALERT_MODAL_TYPE.DUPLICATED_ACCOUNT) {
    return (
      <AlertContactModal
        open={open}
        header="พบข้อมูลซ้ำในระบบ"
        content="กรุณาติดต่อเจ้าหน้าที่เพื่อรับความช่วยเหลือ"
        onClose={onClose}
        redirectToOnClose={redirectToOnClose}
      />
    )
  }

  if (type === ALERT_MODAL_TYPE.CONTACT_US) {
    return (
      <AlertContactModal
        open={open}
        header="ติดต่อแอดมิน"
        content="สะดวกสบายในการติดต่อเราได้จากช่องทางด้านล่าง"
        onClose={onClose}
        isShowIcon={false}
        redirectToOnClose={redirectToOnClose}
      />
    )
  }
  if (type === ALERT_MODAL_TYPE.WALLET_REVOKED) {
    return (
      <AlertContactModal
        open={open}
        header="โดนยกเลิกเพราะมีบางอย่างผิดพลาด"
        content="สามารถติดต่อเจ้าหน้าที่ หากท่านต้องการความช่วยเหลือ"
        onClose={onClose}
        isShowIcon={false}
      />
    )
  }
  if (type === ALERT_MODAL_TYPE.WALLET_CANCELED) {
    return (
      <AlertContactModal
        open={open}
        header="ไม่ตรงตามเงื่อนไข"
        content="สามารถติดต่อเจ้าหน้าที่ หากท่านต้องการความช่วยเหลือ"
        onClose={onClose}
        isShowIcon={false}
      />
    )
  }

  if (type === ALERT_MODAL_TYPE.BANNED_USER) {
    return (
      <AlertContactModal
        open={open}
        header="เบอร์โทรศัพท์มือถือผิดปกติ"
        content="กรุณาติดต่อเจ้าหน้าที่เพื่อรับความช่วยเหลือ"
        onClose={onClose}
        columnClass={columnClass}
        redirectToOnClose={redirectToOnClose}
      />
    )
  }

  if (type === ALERT_MODAL_TYPE.LOCKED_ACCOUNT) {
    return (
      <AlertContactModal
        open={open}
        onClose={onClose}
        columnClass={columnClass}
        isLock
        redirectToOnClose={redirectToOnClose}
      />
    )
  }

  const payload = {
    type,
    onClose,
    // t,
    data,
    systemStatus,
  }
  const alertProps = getAlertProps(payload)

  return (
    <CommonAlertModal
      id={`${type}__modal`}
      open={open}
      onClose={onClose}
      {...alertProps}
      modalComponent="antd"
      columnClass={columnClass}
      redirectToOnClose={redirectToOnClose}
    />
  )
}

export const AlertModalV2 = NiceModal.create((props: AlertModalProps) => {
  const modal = useModal()
  return (
    <AlertModal
      {...props}
      open={modal.visible}
      onClose={() => {
        if (props.onClose) {
          props.onClose()
        }
        modal.hide()
      }}
    />
  )
})

export default AlertModal
