import { PRODUCT_NAME } from '@pig-common/models/buildtime-constant'

const ProductName: Record<string, string> = {
  PIGSPIN: 'PIGSPIN',
  PIG_SPIN: 'PIGSPIN',
  PIGBET: 'PIGBET',
  PIG_BET: 'PIGBET',
}

const checkMatchProductName = (name: string) => {
  return (
    !!name &&
    !!ProductName[name.toUpperCase()] &&
    ProductName[name.toUpperCase()] === PRODUCT_NAME
  )
}

export const validProductName = (name: string) => {
  return ProductName[name.toUpperCase()] || 'n/a'
}

export default checkMatchProductName
