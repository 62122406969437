import React from 'react'
import CommonModal from '../CommonModal/CommonModal'
import {
  TermAndCondition,
  termAndConditionContent,
} from '../../TermAndCondition'

export type TermAndConditionModalProps = {
  open: boolean
  closableBackDrop?: boolean
  onClose: () => void
}

const TermAndConditionModal = ({
  open,
  closableBackDrop = true,
  onClose,
}: TermAndConditionModalProps): JSX.Element => {
  const { title, content } = termAndConditionContent

  return (
    <CommonModal
      open={open}
      closableBackDrop={closableBackDrop}
      scrollableContainerSelector="div.ps-tac__body"
      onClose={onClose}
    >
      <TermAndCondition title={title} content={content} onClose={onClose} />
    </CommonModal>
  )
}

export default TermAndConditionModal
