import { PigHuntItem } from '@pig-common/hooks/usePigHunt'
import checkMatchProductName from '@pig-common/utils/checkMatchProductName'
import dayjs from 'dayjs'

const pageKeyPigHunt: Record<string, string> = {
  '/game': 'GAME',
  '/game/search': 'GAME',
  '/games/[slug]?types=favorite': 'GAME_FAVORITE',
  '/games/search?types=favorite': 'GAME_FAVORITE',
  '/games/[slug]?types=recent': 'GAME_RECENT',
  '/games/search?types=recent': 'GAME_RECENT',
  '/games/search?types=new': 'GAME_NEW',
  '/games/[slug]?types=popular': 'GAME_POPULAR',
  '/games/search?types=popular': 'GAME_POPULAR',
  '/games/search?types=demo': 'GAME_DEMO',
  '/affiliate': 'AFFILIATE',
  '/lobby': 'LOBBY',
  '/daily-quests': 'DAILY_QUEST',
  '/promotions': 'PROMOTION',
  '/guild': 'GUILD',
  '/daily/spin': 'DAILY_SPIN',
  '/champion': 'CHAMPION',
  '/daily-quests/champion': 'CHAMPION',
  '/pig-shop': 'SHOP',
}

export const mapPigHuntPage = (page: string, type?: any): string => {
  const mappedPage =
    pageKeyPigHunt[
      `${page.replace('/mobile', '')}${type ? `?types=${type}` : ''}`
    ]
  return mappedPage
}

export const mapPigHuntCharacterImage = (data: string): string => {
  switch (data) {
    case 'LUCKY_PIG':
      return 'ufo'
    case 'WIN_PIG':
      return 'cheer'
    case 'RICH_PIG':
    default:
      return 'coin'
  }
}

export const mapPigHuntData = (data: any): PigHuntItem => {
  const {
    uid,
    pigCharacter,
    displayPage,
    positionX,
    positionY,
    pigThaiDay,
    startDatetime,
    endDatetime,
  } = data
  return {
    uid,
    image: mapPigHuntCharacterImage(pigCharacter),
    location: {
      page: displayPage,
      x: positionX,
      y: positionY,
    },
    date: {
      thaiDay: pigThaiDay,
      timeStart: dayjs(startDatetime).add(7, 'hour').tz('Asia/Bangkok'),
      timeEnd: dayjs(endDatetime).add(7, 'hour').tz('Asia/Bangkok'),
    },
  }
}

export const mapPigHuntMessage = (data: any): PigHuntItem | null => {
  const {
    uid,
    pig_character,
    display_page,
    x,
    y,
    pig_thai_day,
    start_time,
    end_time,
    host,
  } = JSON.parse(data.slice(2, -1).trim())
  if (checkMatchProductName(host)) {
    return {
      uid,
      image: mapPigHuntCharacterImage(pig_character),
      location: {
        page: display_page,
        x,
        y,
      },
      date: {
        thaiDay: pig_thai_day,
        timeStart: dayjs(start_time).add(7, 'hour').tz('Asia/Bangkok'),
        timeEnd: dayjs(end_time).add(7, 'hour').tz('Asia/Bangkok'),
      },
    }
  }
  return null
}
