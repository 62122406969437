import React from 'react'
import { Button, BUTTON_TYPE } from '../Button'
import { Images } from '../Images'
import AlertModal, { ALERT_MODAL_TYPE } from '../Modal/AlertModal/AlertModal'
import AlertWithImageModal from '../Modal/AlertWithImageModal/AlertWithImageModal'

export type TimeData = {
  day?: number
  hour?: number
  min?: number
  sec?: number
}

const ModalSuccession = ({
  active,
  errorCode,
  claimQuestTicketContent,
  alertModalType,
  messageError,
  customImage,
  closeModalHandler,
}: {
  active: boolean
  errorCode: number | string
  claimQuestTicketContent: JSX.Element
  alertModalType?: ALERT_MODAL_TYPE
  messageError: React.ReactNode
  customImage?: JSX.Element
  closeModalHandler: () => void
}) => {
  return active ? (
    <AlertWithImageModal
      id="claim-quest-success-modal"
      open={active}
      contentClass="ps-modal-congrat text-center relative"
      image={
        customImage ? (
          customImage
        ) : (
          <Images
            src="/images/dailyquests/claim-success.png"
            alt="image-success-modal"
            width={210}
            height={210}
            className="claim-success-modal"
          />
        )
      }
      content={claimQuestTicketContent}
      closableBackDrop={false}
      footer={
        <div className="d-flex justify-content-end">
          <Button
            type={BUTTON_TYPE.PLAIN_TEXT}
            onClick={() => closeModalHandler()}
            className="p-1 lh-1 ps-text-primary-1"
          >
            ตกลง
          </Button>
        </div>
      }
      buttonClose={{ fnc: closeModalHandler, txt: 'ตกลง' }}
    />
  ) : (
    <>
      {errorCode === 500 &&
      alertModalType &&
      alertModalType !== ALERT_MODAL_TYPE.BANNED_USER &&
      alertModalType !== ALERT_MODAL_TYPE.PIN_LOCKED ? (
        <AlertModal
          open={active}
          onClose={closeModalHandler}
          type={alertModalType}
        />
      ) : (
        <AlertWithImageModal
          id="claim-quest-error-modal"
          open={active}
          image={
            <Images
              src="/images/dailyquests/pig-error.png"
              alt="image-success-modal"
              width={75}
              height={71}
            />
          }
          content={messageError}
          contentClass="text-center"
          closableBackDrop={false}
          buttonClose={{ fnc: closeModalHandler }}
        />
      )}
    </>
  )
}

export default ModalSuccession
