import cx from 'classnames'
import { Images } from '@pig-common/components/Images'
import { CLOUD_STORAGE_CDN } from '@pig-common/models/buildtime-constant'
import { GameCompanyTypes } from '@pig-common/models/shared-types'
import { StaticImageData } from 'next/image'
import { GameIconNewFrame } from '../GameIconNewFrame'

export type GameIconProps = {
  src: string | StaticImageData
  alt?: string
  width?: number
  height?: number
  layout?: any
  priority?: boolean
  quality?: number
  campaign?: string
  isCampaign?: boolean
  ratioChange?: boolean
  isNew?: boolean
  refType?: GameCompanyTypes | string
  className?: string | null
  newFrameClassName?: string | null
  onClick?: () => void
}

const GameIcon = ({
  src,
  alt = '',
  width = 20,
  height = 20,
  layout = 'fixed',
  priority = false,
  quality = 75,
  campaign = '',
  isCampaign = false,
  ratioChange = false,
  isNew = false,
  refType,
  className,
  newFrameClassName,
  onClick = () => {},
}: GameIconProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={cx('ps-game-icon', className)} onClick={onClick}>
      {isCampaign && (
        <Images
          src={`${CLOUD_STORAGE_CDN}/pigspin-assets/campaigns/${campaign}/icon.png`}
          className="ps-game-icon__cover  ps-game-icon__cover--top-layer"
          alt={`${alt}-top-layer`}
        />
      )}
      <Images
        src={src}
        layout={layout}
        className="ps-game-icon__cover"
        width={width}
        height={height}
        quality={quality}
        alt={alt}
        priority={priority}
        ratioChange={ratioChange}
      />
      {isNew && <GameIconNewFrame width={width} height={height} />}
      {refType && (
        <div className={cx(newFrameClassName, 'ps-game-icon__company-frame')}>
          <Images
            src={`${CLOUD_STORAGE_CDN}/assets/${refType}/jacket.webp`}
            layout={layout}
            width={width}
            height={height}
            alt={alt}
          />
        </div>
      )}
    </div>
  )
}
export default GameIcon
