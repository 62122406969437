import { motion } from 'framer-motion'
import { useMemo, useRef } from 'react'
import {
  ANNOUNCEMENT_TYPE,
  AnnouncementItem,
} from '@pig-common/types/Announcement.type'
import {
  StyledAnnouncementEmergencyToast,
  AnnouncementContent,
  AnnouncementCloseButton,
  StyledAnnouncementToast,
} from './styled'

const AnnouncementToast = ({
  announcement,
  onCloseToast,
}: {
  announcement: AnnouncementItem
  onCloseToast: (announcement: AnnouncementItem) => void
}) => {
  const { type, content } = announcement
  const refToast = useRef<HTMLDivElement>(null)
  const animHeight = useMemo(
    () =>
      (refToast?.current?.clientHeight || 0) +
      (type === ANNOUNCEMENT_TYPE.EMERGENCY ? 8 : 16),
    [type, refToast.current?.clientHeight],
  )

  return (
    <motion.div
      data-testid={`announcement-toast-${announcement.code}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      layout
    >
      {type === ANNOUNCEMENT_TYPE.EMERGENCY ? (
        <StyledAnnouncementEmergencyToast height={animHeight}>
          <AnnouncementContent ref={refToast}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </AnnouncementContent>
        </StyledAnnouncementEmergencyToast>
      ) : (
        <StyledAnnouncementToast type={type} height={animHeight}>
          <AnnouncementContent ref={refToast}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <AnnouncementCloseButton
              onClick={() => onCloseToast(announcement)}
            />
          </AnnouncementContent>
        </StyledAnnouncementToast>
      )}
    </motion.div>
  )
}

export default AnnouncementToast
