import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { RecoilRoot, useRecoilValue } from 'recoil'
import { themeState } from '@pig-casino/recoils'
import TagManager from 'react-gtm-module'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RecoilDevTools } from 'recoil-gear'
import { useRouter } from 'next/router'
import { AppProps } from 'next/app'
import { ConfigProvider } from '@pig-frontend/uikit'
import ErrorBoundary from '@pig-casino/components/error-boundary'
import {
  COOKIES_KEY,
  UtmCookiesType,
  useCookies,
} from '@pig-common/utils/cookies'
import Layout from '@pig-casino/components/layout'
import { UTM } from '@pig-casino/types/utm.type'
import { NODE_ENV, GTM_TRACKING_ID, APP_ENV } from '@pig-casino/constants/env'
import 'swiper/css'
import 'swiper/css/pagination'
import '@pig-common/api/api-interceptor'
import '@pig-common/styles/scss/main.scss'
import '@pig-frontend/uikit/scss'
import th from 'dayjs/locale/th'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import updateLocale from 'dayjs/plugin/updateLocale'
import relativeTime from 'dayjs/plugin/relativeTime'

const SocketProvider = dynamic(
  () => import('@pig-common/context/Provider/Socket'),
)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function AppWrapper({ children }) {
  const theme = useRecoilValue(themeState.theme)
  const router = useRouter()
  const [, setCookies] = useCookies<UtmCookiesType>([COOKIES_KEY.UTM])
  dayjs.locale(th)
  dayjs.extend(duration)
  dayjs.extend(updateLocale)
  dayjs.extend(relativeTime)
  dayjs.extend(utc)
  dayjs.extend(timezone)

  // init GTM only on client side
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (APP_ENV === 'pre-prod' || APP_ENV === 'prod') {
        TagManager.initialize({ gtmId: `${GTM_TRACKING_ID}` })
      }

      // NOTE : Collect UTM parameters
      const { query } = router
      const { protocol, hostname, pathname } = window.location
      const landingUTM: UTM = {}
      const keyMapper = Object.keys(query) as [keyof UTM]
      const queryValues = Object.values(query)
      keyMapper.forEach((k, i) => {
        if (Array.isArray(queryValues[i])) {
          const [tmp] = queryValues[i] as string[]
          landingUTM[k] = tmp
          return
        }
        landingUTM[k] = queryValues[i] as string
      })

      setCookies(COOKIES_KEY.UTM, {
        firstVisitUrl: `${protocol}//${hostname}${pathname}`,
        utmId: landingUTM.utm_id,
        utmSource: landingUTM.utm_source,
        utmMedium: landingUTM.utm_medium,
        utmCampaign: landingUTM.utm_campaign,
        utmTerm: landingUTM.utm_term,
        utmContent: landingUTM.utm_content,
      })
    }
  }, [])

  return (
    <ConfigProvider>
      <Layout theme={theme}>
        {/* Make id for using portal modal with global scss variables; Ex. Modal.confirm or Modal.info with global scss variables */}
        <div id="modal-root">{children}</div>
      </Layout>
    </ConfigProvider>
  )
}

function App({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <SocketProvider>
              <AppWrapper>
                <Component {...pageProps} />
              </AppWrapper>
            </SocketProvider>
          </Hydrate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
        {NODE_ENV === 'development' && <RecoilDevTools />}
      </RecoilRoot>
    </ErrorBoundary>
  )
}

export default App
