import React, { CSSProperties } from 'react'
import classNames from 'classnames'
import { Button, BUTTON_TYPE } from '../Button'
import { Images } from '@pig-common/components/Images'
import { GameIcon } from '../GameIcon'
import { gameAssetUrl } from '@pig-common/utils/utils'
import { GameItem } from '@pig-common/services/games'
import isInNewGameDuration from '@pig-common/utils/getNewGameDuration'
import {
  CheckCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'

export enum ALERT_TYPE {
  ERROR = 'error',
  SUCCESS = 'success',
  NONE = '',
}

export type AlertProps = {
  id?: string
  type?: ALERT_TYPE
  image?: string
  imageUrl?: string
  imgWidth?: string | number
  imgHeight?: string | number
  gameCode?: string
  header?: React.ReactNode | string
  footer?: React.ReactNode | string
  closeButton?: boolean
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
  onClose?: () => void
  isShowIcon?: boolean
  game?: GameItem
}

const getIconClassName = (type: ALERT_TYPE) => {
  switch (type) {
    case ALERT_TYPE.SUCCESS:
      return (
        <CheckCircleOutlined className="ps-alert__icon ps-alert__icon--success" />
      )
    case ALERT_TYPE.ERROR:
      return (
        <ExclamationCircleOutlined className="ps-alert__icon ps-alert__icon--error" />
      )
    case ALERT_TYPE.NONE:
    default:
      return null
  }
}

const Alert = ({
  id,
  type = ALERT_TYPE.ERROR,
  image,
  imageUrl,
  imgWidth = '44px',
  imgHeight = '44px',
  gameCode,
  header,
  footer,
  closeButton,
  onClose,
  className,
  style,
  isShowIcon = true,
  game,
  children,
}: AlertProps): JSX.Element => {
  const alertClassName = classNames('ps-alert', className)
  const iconClassName = getIconClassName(type)
  const headerClassName = classNames({ 'ps-alert__header': header })
  const bodyModifier = classNames('ps-alert__body', {
    'ps-alert__body--extra-margin-with-icon': isShowIcon && iconClassName,
  })

  const handleClick = () => {
    if (closeButton && typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <div id={id} data-testid={id} className={alertClassName} style={style}>
      {image && <Images src={`/images/${image}`} width={44} height={44} />}
      {imageUrl && <img src={imageUrl} alt={imageUrl} width={44} />}
      {gameCode && (
        <div style={{ width: imgWidth, height: imgHeight }}>
          <GameIcon
            src={gameAssetUrl(game, 'icon')}
            quality={100}
            alt={`${id}-image`}
            isCampaign={false}
            isNew={isInNewGameDuration(game?.created_at)}
            refType={game?.ref_type}
          />
        </div>
      )}
      <div className="ps-alert__content-wrapper">
        <div className={headerClassName}>
          {header && (
            <>
              {isShowIcon && iconClassName}
              <strong data-testid={`${id}-header`}>{header}</strong>
            </>
          )}
          {closeButton && (
            <Button
              type={BUTTON_TYPE.PLAIN_TEXT}
              className="ms-auto p-0"
              onClick={handleClick}
            >
              <CloseOutlined className="ps-alert__close" />
              <span className="visually-hidden">Close</span>
            </Button>
          )}
        </div>

        {!header ? (
          <div className="ps-alert__body--no-header">
            {isShowIcon && iconClassName}
            <div data-testid={`${id}-body`} className="ps-alert__body">
              {children}
            </div>
          </div>
        ) : (
          <div data-testid={`${id}-body`} className={bodyModifier}>
            {children}
          </div>
        )}
        <div data-testid={`${id}-footer`} className="ps-alert__footer">
          {footer}
        </div>
      </div>
    </div>
  )
}

export default Alert
