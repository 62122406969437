const pageLinkCommon = {
  index: '/',
  home: '/home',
  login: '/login',
  lobby: '/lobby',
  lobbyMobile: '/lobby/mobile',
  register: '/register',
  affiliate: '/affiliate',
  promotions: '/promotions',
  promotionsSlug: {
    freeCredit: '/promotions/verified-kyc',
    returnBonus: '/promotions/return-bonus',
    referral: '/campaigns/affiliate',
    deposit20x: '/promotions/deposit-20x',
    returnBonus40x: '/promotions/return-bonus-40x',
  },
  campaigns: '/campaigns',
  notifications: '/notifications',
  faq: '/faq',
  line: '/line',
  profile: {
    home: '/profile',
    setBank: '/profile/set-bank',
    setPhone: '/profile/set-phone',
    setKYC: '/profile/set-kyc',
  },
  maintenance: '/maintenance',
  wallet: '/wallet',
  guild: {
    index: '/guild',
    create: '/guild/create',
    edit: '/guild/edit',
  },
  achievement: '/achievement',
  authenticate: '/authenticate',
  dailyQuests: '/daily-quests',
  pigShop: '/pig-shop',
  daily: {
    spin: '/daily/spin',
  },
  profileDetails: '/profile-details',
  vip: '/vip',
  notfound: '/404',
  champion: '/champion',
}

export { pageLinkCommon }
