import { useState } from 'react'
import Image, { StaticImageData } from 'next/image'
import isEmpty from 'lodash/isEmpty'
import placeholder from '@pig-common/public/images/placeholder/placeholder.png'

export type ImageProps = {
  id?: string
  src: string | StaticImageData
  alt?: string
  width?: number
  height?: number
  layout?: any
  className?: string
  priority?: boolean
  showTitle?: boolean
  quality?: number
  absolute?: boolean
  ratioChange?: boolean
}

const shimmer = (
  w: number | string | undefined,
  h: number | string | undefined,
) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#D4D4D5" offset="20%" />
      <stop stop-color="#AAAAAB" offset="50%" />
      <stop stop-color="#D4D4D5" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#D4D4D5" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str)

const Images = ({
  id,
  src,
  alt = '',
  layout = 'intrinsic',
  width,
  height,
  showTitle = false,
  className = '',
  priority = false,
  quality = 75,
  absolute = false,
  ratioChange = false,
}: ImageProps) => {
  const [isError, setIsError] = useState<boolean>(false)

  const renderImage = () => {
    if (showTitle) {
      return (
        <div style={{ position: 'relative' }}>
          <Image
            id={id}
            data-testid={id}
            alt={alt}
            src={src}
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64, ${toBase64(
              shimmer(width, height),
            )}`}
            width={width}
            height={height}
            className={className}
            priority={priority}
            quality={quality}
            onError={() => {
              setIsError(true)
            }}
          />
          <p
            style={{
              position: 'absolute',
              marginTop: '30%',
              textAlign: 'center',
              top: 0,
              width: '100%',
            }}
          >
            {alt}
          </p>
        </div>
      )
    }
    return (
      <Image
        id={id}
        data-testid={id}
        alt={alt}
        src={src}
        placeholder="blur"
        blurDataURL={`data:image/svg+xml;base64, ${toBase64(
          shimmer(width, height),
        )}`}
        width={width}
        height={height}
        className={className}
        priority={priority}
        quality={quality}
        onError={() => {
          setIsError(true)
        }}
      />
    )
  }

  return (
    <>
      {!isError && !isEmpty(src) ? (
        renderImage()
      ) : (
        <div
          className="ps-image-placeholder"
          style={{
            height: !ratioChange ? `${height}px` : '100%',
            width: '100%',
            position: absolute ? 'absolute' : 'relative',
          }}
        >
          <Image
            id={id}
            data-testid={id}
            alt={`${alt}-placeholder`}
            src={placeholder}
            width={width}
            height={height}
            priority
            quality={100}
          />
        </div>
      )}
    </>
  )
}

export default Images
