import React from 'react'
// REF : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
const flattened = (arr: any[]) => [].concat(...arr)

export function autoLineBreakerWithBr(
  text: string,
  separator: string | RegExp = '\n',
): React.ReactNode[] {
  const textFragments = text?.split(separator)
  return flattened(
    textFragments.map((line, index) => {
      if (index + 1 === textFragments.length) {
        // NOTE : The last line should not contain <br /> after itself.
        return line
      }
      return [
        line,
        React.createElement('br', {
          key: `br${line.toString() + index.toString()}`,
        }),
      ]
    }),
  )
}

export const markX = (text: string, _from: number, _to: number): string => {
  const from = _from
  const to = _to >= text.length ? text.length : _to + 1
  if (from < to)
    return `${text.substr(0, from)}${'*'.repeat(to - from)}${text.substr(to)}`
  return text
}

export function getMaskedPhoneNumber(rawPhoneNumber: string): string {
  if (rawPhoneNumber.length !== 10) {
    return rawPhoneNumber
  }
  const markedPhoneNumber = markX(rawPhoneNumber, 0, 7)
  return markedPhoneNumber
}

export const markRealX = (text: string, _from: number, _to: number): string => {
  const from = _from
  const to = _to >= text.length ? text.length : _to + 1
  if (from < to)
    return `${text.substr(0, from)}${'x'.repeat(to - from)}${text.substr(to)}`
  return text
}

export function getMaskedXPhoneNumber(rawPhoneNumber: string): string {
  if (rawPhoneNumber.length !== 10) {
    return rawPhoneNumber
  }
  const markedPhoneNumber = markRealX(rawPhoneNumber, 2, 7)
  return markedPhoneNumber
}

export function getPhoneFormat(rawPhoneNumber: string): string {
  if (rawPhoneNumber.length !== 10) {
    return rawPhoneNumber
  }
  const firstPieces = rawPhoneNumber.slice(0, 3)
  const secondPieces = rawPhoneNumber.slice(3, 7)
  const lastPieces = rawPhoneNumber.slice(7, rawPhoneNumber.length)

  return [firstPieces, secondPieces, lastPieces].join('-')
}

export function getBankFormat(rawBankNumber: string): string {
  if (rawBankNumber.length !== 10) {
    return rawBankNumber
  }
  const firstPieces = rawBankNumber.slice(0, 3)
  const secondPieces = rawBankNumber.slice(3, 4)
  const thirdPieces = rawBankNumber.slice(4, 9)
  const lastPieces = rawBankNumber.slice(9, rawBankNumber.length)

  return [firstPieces, secondPieces, thirdPieces, lastPieces].join('-')
}
