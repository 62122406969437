import {
  ERROR_STATUS_VALUE,
  SystemStatusType,
} from '@pig-common/recoils/modules/system-status'

const mapToastErrorState = ({
  systemStatus,
  isAuthen,
}: {
  systemStatus: SystemStatusType
  isAuthen: boolean
}) => {
  const { SCBError, isSCBUser, underConstruction, friendInvite } = systemStatus

  const { GameError, WithdrawError, DepositError, Register } =
    systemStatus.Toast

  if (underConstruction) return ERROR_STATUS_VALUE.UNDER_CONSTRUCTION
  if (Register === true && !isAuthen) {
    return ERROR_STATUS_VALUE.CLOSE_REGISTER
  }

  const gameErrorValue = GameError
    ? ERROR_STATUS_VALUE.GAME
    : ERROR_STATUS_VALUE.NONE
  let SCBErrorValue =
    isSCBUser && SCBError
      ? ERROR_STATUS_VALUE.SCB_DEPOSIT
      : ERROR_STATUS_VALUE.NONE
  const withdrawErrorValue = WithdrawError
    ? ERROR_STATUS_VALUE.WITHDRAW
    : ERROR_STATUS_VALUE.NONE
  let depositErrorValue = DepositError
    ? ERROR_STATUS_VALUE.DEPOSIT
    : ERROR_STATUS_VALUE.NONE

  // NOTE : SCB user Special case
  if (isSCBUser && SCBError && DepositError) {
    depositErrorValue = ERROR_STATUS_VALUE.DEPOSIT
    SCBErrorValue = ERROR_STATUS_VALUE.NONE
  }

  const totalValue =
    gameErrorValue * SCBErrorValue * withdrawErrorValue * depositErrorValue

  if (!(totalValue > 1) && friendInvite === true) {
    return ERROR_STATUS_VALUE.FRIEND_INVITE
  }
  return totalValue as ERROR_STATUS_VALUE
}

export default mapToastErrorState
