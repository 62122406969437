import { CloseOutlined } from '@ant-design/icons'
import { ANNOUNCEMENT_TYPE } from '@pig-common/types/Announcement.type'
import styled from 'styled-components'

export const AnnouncementContent = styled.div`
  height: min-content;
`
export const AnnouncementCloseButton = styled(CloseOutlined)`
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 16px;
  pointer-events: all;
  user-select: none;
  color: #2a2a2ecc;
`
export const StyledAnnouncementToast = styled.div<{
  height?: number
  type?: ANNOUNCEMENT_TYPE
}>`
  width: 100%;
  color: white;
  backdrop-filter: blur(2.5px);
  padding: 8px 16px;
  background-color: ${({ type }) =>
    type === ANNOUNCEMENT_TYPE.ADVERTISE ? '#9970ffCC' : '#2A2A2ECC'};
  z-index: 20;
  div,
  p,
  a {
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    height: min-content;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--color-yellow-200);
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
  }
  a {
    color: white;
    background-color: var(--color-primary-1);
    padding: 1px 3px;
    border-radius: 5px;
    display: inline-block;
    height: 19px;
    pointer-events: all;
  }
  > * {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
  ${AnnouncementCloseButton}, svg {
    font-size: 16px;
    color: ${({ type }) =>
      type === ANNOUNCEMENT_TYPE.DEFAULT ? 'white' : 'black'};
    opacity: 0.6;
  }
`
export const StyledAnnouncementEmergencyToast = styled(
  StyledAnnouncementToast,
)<{ height?: number }>`
  width: 100%;
  color: white;
  backdrop-filter: blur(2.5px);
  padding: 4px 16px;
  height: min-content;
  width: 100%;
  color: white;
  backdrop-filter: blur(2.5px);
  padding: 4px 16px;
  background-color: #c7373ecc;
  height: min-content;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
    line-height: 20px;
  }
  > * {
    color: #f6c244cc;
    font-size: 20px;
    font-weight: 400;
    line-height: 18px;
  }
`
