import { ToastMessage } from '@pig-common/recoils/modules/mini-toast'
import { motion, usePresence } from 'framer-motion'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

const AnnouncementContent = styled.div`
  height: min-content;
`
const StyledMiniToast = styled(motion.div)`
  display: flex;
  justify-content: center;
  min-width: 120px;
  max-width: 320px;
  margin: 4px auto 0px;
  padding: 12px 18px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 8px;
  backdrop-filter: blur(2.5px);
  box-shadow: 0 0 4px var(--color-primary-1);
  color: var(--color-greyscale-600);
  border-radius: 8px;
  box-shadow: none;
  cursor: pointer;
  border: 2px solid;
  border-color: var(--color-lightblue-200);
  background-color: var(--color-lightblue-100);
  &.success {
    border-color: var(--color-green-200);
    background-color: var(--color-green-100);
  }
  &.error {
    border-color: var(--color-red-200);
    background-color: var(--color-red-100);
  }
`
const MiniToast = ({
  toast,
  onClick,
}: {
  toast: ToastMessage
  onClick?: () => void
}) => {
  const refToast = useRef<HTMLDivElement>(null)
  const [isPresent, safeToRemove] = usePresence()
  useEffect(() => {
    if (!isPresent) safeToRemove()
  }, [isPresent])
  return (
    <StyledMiniToast
      data-testid={toast.id}
      className={`${toast.type}`}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      layout
    >
      <AnnouncementContent ref={refToast} onClick={() => onClick && onClick()}>
        {toast.message}
      </AnnouncementContent>
    </StyledMiniToast>
  )
}

export default MiniToast
