import { BankInfo, GameCompanyTypes } from '@pig-common/models/shared-types'

export const bankList: BankInfo[] = [
  {
    bankId: '1',
    bankCode: 'KBANK',
    bankLogo: 'bank-ksb.png',
    bankName: 'ธนาคาร กสิกรไทย',
    bankNoLength: '10',
  },
  {
    bankId: '2',
    bankCode: 'SCB',
    bankLogo: 'bank-scb.png',
    bankName: 'ธนาคาร ไทยพาณิชย์',
    bankNoLength: '10',
  },
  {
    bankId: '3',
    bankCode: 'KTB',
    bankLogo: 'bank-ktb.png',
    bankName: 'ธนาคาร กรุงไทย',
    bankNoLength: '10',
  },
  {
    bankId: '4',
    bankCode: 'BBL',
    bankLogo: 'bank-bkb.png',
    bankName: 'ธนาคาร กรุงเทพ',
    bankNoLength: '10',
  },
  {
    bankId: '5',
    bankCode: 'BAY',
    bankLogo: 'bank-ksi.png',
    bankName: 'ธนาคาร กรุงศรี',
    bankNoLength: '10',
  },
  {
    bankId: '6',
    bankCode: 'TTB',
    bankLogo: 'bank-ttb.png',
    bankName: 'ธนาคาร ทีเอ็มบีธนชาต',
    bankNoLength: '10',
  },
  {
    bankId: '8',
    bankCode: 'LHBANK',
    bankLogo: 'bank-lhb.png',
    bankName: 'ธนาคาร LH bank',
    bankNoLength: '10',
  },
  {
    bankId: '10',
    bankCode: 'UOB',
    bankLogo: 'bank-uob.png',
    bankName: 'ธนาคาร UOB',
    bankNoLength: '10',
  },
  {
    bankId: '11',
    bankCode: 'GSB',
    bankLogo: 'bank-gsb.png',
    bankName: 'ธนาคาร ออมสิน',
    bankNoLength: '12',
  },
  {
    bankId: '14',
    bankCode: 'KK',
    bankLogo: 'bank-knk.png',
    bankName: 'ธนาคาร เกียรตินาคิน',
    bankNoLength: '10',
  },
]

export const newGameDuration = 30 // days

export const gameCompanyTitleMapper: Partial<Record<GameCompanyTypes, string>> =
  {
    EVO: 'Evoplay',
    PG: 'PG SOFT',
    JOKER: 'Joker',
    PNG: 'Play N Go',
  }
