import React, { ReactNode } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { Alert, ALERT_TYPE } from '../../Alert'
import CommonModal from '../CommonModal/CommonModal'
import { GameItem } from '@pig-common/services/games'

export type CommonAlertModalProps = {
  id?: string
  open?: boolean
  closableBackDrop?: boolean
  type?: ALERT_TYPE
  image?: string
  imageUrl?: string
  imgWidth?: string | number
  imgHeight?: string | number
  gameCode?: string
  header?: ReactNode | string
  footer?: ReactNode | string
  closeButton?: boolean
  children?: ReactNode
  onClose?: () => void
  redirectToOnClose?: string
  isShowIcon?: boolean
  columnClass?: string
  game?: GameItem
  createdAt?: string
  modalComponent?: 'antd' | 'ps'
}

const StyledAntdModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 0;
  }
`

const CommonAlertModal = ({
  id,
  open,
  closableBackDrop = true,
  type,
  image,
  imageUrl,
  imgWidth,
  imgHeight,
  gameCode,
  header,
  footer,
  closeButton,
  children,
  onClose,
  redirectToOnClose,
  isShowIcon = true,
  columnClass = '',
  game,
  createdAt,
  modalComponent = 'ps',
}: CommonAlertModalProps): JSX.Element => {
  const router = useRouter()

  const onCloseHandle = () => {
    onClose()
    if (redirectToOnClose) {
      router.push(redirectToOnClose)
    }
  }

  const content = (
    <Alert
      id={id}
      type={type}
      image={image}
      imageUrl={imageUrl}
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      gameCode={gameCode}
      header={header}
      footer={footer}
      closeButton={closeButton}
      onClose={onCloseHandle}
      isShowIcon={isShowIcon}
      game={game}
    >
      {children}
    </Alert>
  )

  if (modalComponent === 'antd') {
    return (
      <StyledAntdModal
        centered
        destroyOnClose
        closable={false}
        footer={null}
        title={null}
        visible={open}
        onCancel={onCloseHandle}
        getContainer={false}
      >
        {content}
      </StyledAntdModal>
    )
  }
  return (
    <CommonModal
      open={open}
      closableBackDrop={closableBackDrop}
      onClose={onCloseHandle}
      columnClass={columnClass}
    >
      {content}
    </CommonModal>
  )
}

export const CommonAlertModalV2 = NiceModal.create(
  (props: CommonAlertModalProps) => {
    const modal = useModal()
    return (
      <CommonAlertModal
        {...props}
        open={modal.visible}
        onClose={() => {
          modal.hide()
        }}
      />
    )
  },
)
export default CommonAlertModal
