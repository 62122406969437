import { useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useRouter } from 'next/router'
import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  mapPigHuntData,
  mapPigHuntMessage,
  mapPigHuntPage,
} from '@pig-common/mappers/mapPigHunt'
import { API_URL } from '@pig-common/models/buildtime-constant'
import { pighuntApi } from '@pig-casino/api'
import { ParsedUrlQuery } from 'querystring'

type PigHuntHookRequest = {
  customerCode: string
  cfid: string
}
export type PigHuntItem = {
  uid: string
  image: string
  location: {
    page: string
    x: number
    y: number
  }
  date: {
    thaiDay: string
    timeStart: Dayjs
    timeEnd: Dayjs
  }
}
interface PigHuntCookies {
  [customerCode: string]: string[]
}
const usePigHunt = ({ customerCode, cfid }: PigHuntHookRequest) => {
  const router = useRouter()
  const [cookies, setCookies] = useCookies<PigHuntCookies>([
    COOKIES_KEY.PIGHUNT,
  ])
  const [pigHuntList, setPigHuntList] = useState<PigHuntItem[]>([])

  const extractPageType = (data: ParsedUrlQuery) => {
    const type =
      data?.favorite === 'true'
        ? 'favorite'
        : data.filterType === 'pupular'
        ? 'popular'
        : data.filterType === 'recent'
        ? 'recent'
        : undefined
    return type
  }
  const { refetch: fetchPigHunt } = useQuery(
    ['pighunt', router.pathname],
    async () => {
      try {
        const keyPage = mapPigHuntPage(
          router.pathname,
          extractPageType(router?.query),
        )
        if (!keyPage || !cfid) throw new Error()
        const response = await pighuntApi.list({ keyPage })
        const mappedResult = response.data.schedule.map(mapPigHuntData)
        setPigHuntList(mappedResult)
        return mappedResult
      } catch {
        setPigHuntList([])
        return []
      }
    },
    {
      enabled: false,
    },
  )

  const connectPigHunt = async () => {
    if (cfid) {
      const sse = new EventSource(
        `${API_URL}/v1/notification/events/channel/pig-hunt?host=PIG_BET&token=${cfid
          .replace('bearer', '')
          .trim()}`,
      )
      sse.onmessage = (event) => {
        setPigHuntList((prev) => {
          const newPig = mapPigHuntMessage(event.data)
          if (!newPig) return [...prev]
          return [...prev, newPig]
        })
      }
      sse.onopen = () => {}
    }
  }

  const activedPigHunt = useMemo(() => {
    const filterActivePigHunt = (data: PigHuntItem[]): PigHuntItem[] => {
      const currentDate = dayjs()
      return [...data].filter((pig) => {
        const isHunted =
          customerCode &&
          cookies[COOKIES_KEY.PIGHUNT]?.[customerCode] &&
          cookies[COOKIES_KEY.PIGHUNT]?.[customerCode].includes(pig.uid)
        if (isHunted) return false
        const startDiff = pig.date.timeStart.diff(currentDate, 's')
        const endDiff = pig.date.timeEnd.diff(currentDate, 's')
        if (startDiff < 1 && endDiff > 0) {
          return (
            mapPigHuntPage(router.pathname, extractPageType(router?.query)) ===
            pig.location.page
          )
        }
        return false
      })
    }
    return pigHuntList.length > 0 ? filterActivePigHunt(pigHuntList) : []
  }, [pigHuntList])

  const { mutateAsync: collectPigHunt } = useMutation({
    mutationFn: async (
      data: PigHuntItem,
    ): Promise<{ amount: number; message: string } | null> => {
      try {
        const hunted = cookies[COOKIES_KEY.PIGHUNT]?.[customerCode] || []
        setCookies(COOKIES_KEY.PIGHUNT, {
          [customerCode]: [...hunted, data.uid],
        })
        const response = await pighuntApi.claim({
          uidPigHunt: data.uid,
          pageKey: data.location.page,
        })
        return {
          amount: response?.data?.amount || 0,
          message: response?.data?.message || '',
        }
      } catch {
        return null
      }
    },
  })

  useEffect(() => {
    fetchPigHunt()
  }, [router])

  return {
    activedPigHunt,
    connectPigHunt,
    collectPigHunt,
  }
}

export default usePigHunt
