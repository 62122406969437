import React, { ReactNode } from 'react'
import { ALERT_TYPE } from '@pig-common/components/Alert'
import CommonAlertModal from '../CommonAlertModal/CommonAlertModal'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'

export type AlertWithImageModalProps = {
  id?: string
  type?: ALERT_TYPE
  open: boolean
  image?: JSX.Element
  imageWidth?: number
  imageHeight?: number
  content: ReactNode | string
  contentClass?: string
  closableBackDrop?: boolean
  footer?: JSX.Element
  buttonClose: {
    fnc: () => void
    txt?: string
    className?: string
  }
  buttonAccept?: {
    fnc: () => void
    txt?: string
  }
  redirectToOnClose?: string
}

const AlertWithImageModal = ({
  id = 'alert-image-modal',
  type,
  open,
  image,
  imageWidth,
  imageHeight,
  content,
  contentClass,
  footer,
  closableBackDrop,
  buttonClose,
  buttonAccept,
  redirectToOnClose,
}: AlertWithImageModalProps): JSX.Element => {
  const buildFooter = () => {
    return (
      <div className="d-flex justify-content-end">
        {footer || (
          <>
            {buttonClose.fnc && (
              <Button
                id={`${id}-button`}
                type={BUTTON_TYPE.PLAIN_TEXT}
                size={BUTTON_SIZE.LG}
                onClick={buttonClose.fnc}
                className={
                  buttonClose?.className || 'p-1 lh-1 ps-text-greyscale-2'
                }
              >
                {buttonClose.txt || 'common:cancel'}
              </Button>
            )}
            {buttonAccept?.fnc && (
              <Button
                id={`${id}-button`}
                type={BUTTON_TYPE.PLAIN_TEXT}
                size={BUTTON_SIZE.LG}
                onClick={buttonAccept.fnc}
                className="p-1 lh-1 ps-text-primary-1"
              >
                {buttonAccept.txt || 'ตกลง'}
              </Button>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    buttonClose &&
    buttonClose.fnc && (
      <CommonAlertModal
        id={id}
        type={type || ALERT_TYPE.NONE}
        open={open}
        closableBackDrop={closableBackDrop}
        onClose={buttonClose.fnc}
        redirectToOnClose={redirectToOnClose}
        footer={buildFooter()}
      >
        <div className={contentClass}>
          {image}
          <div data-testid={`${id}-content`} className="mt-2">
            {content}
          </div>
        </div>
      </CommonAlertModal>
    )
  )
}

export default AlertWithImageModal
