import React, { ReactNode } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '@pig-common/components/Button'
import CommonAlertModal from '../CommonAlertModal/CommonAlertModal'

export type AlertConfirmationModalProps = {
  id?: string
  open: boolean
  columnClass?: string
  closableBackDrop?: boolean
  isShowIcon?: boolean
  image?: string
  imageUrl?: string
  imgWidth?: string | number
  imgHeight?: string | number
  gameCode?: string
  hideOnClose?: boolean
  header: ReactNode
  content: ReactNode
  buttonCloseText?: string
  buttonConfirmText?: string
  onClose: () => void
  onConfirm: () => void
}

const AlertConfirmationModal = ({
  id = 'alert-confirmation-modal',
  open,
  columnClass,
  closableBackDrop = true,
  isShowIcon = true,
  image,
  imageUrl,
  imgWidth,
  imgHeight,
  gameCode,
  header,
  content,
  buttonCloseText,
  buttonConfirmText,
  hideOnClose,
  onClose,
  onConfirm,
}: AlertConfirmationModalProps) => {
  const onCloseHandle = () => {
    if (onClose) onClose()
  }
  const footer = (
    <div className="d-flex justify-content-end mt-2">
      {!hideOnClose && (
        <Button
          type={BUTTON_TYPE.PLAIN_TEXT}
          size={BUTTON_SIZE.LG}
          id={`${id}__close-button`}
          onClick={onCloseHandle}
          className="p-1 lh-1 ps-text-greyscale-4"
        >
          {buttonCloseText || 'ยกเลิก'}
        </Button>
      )}
      <Button
        type={BUTTON_TYPE.PLAIN_TEXT}
        size={BUTTON_SIZE.LG}
        className="p-1 lh-1 ps-text-primary-1"
        id={`${id}__confirm-button`}
        onClick={onConfirm}
      >
        {buttonConfirmText || 'ยืนยัน'}
      </Button>
    </div>
  )

  return (
    <CommonAlertModal
      id={id}
      columnClass={columnClass}
      open={open}
      closableBackDrop={closableBackDrop}
      isShowIcon={isShowIcon}
      image={image}
      imageUrl={imageUrl}
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      gameCode={gameCode}
      header={header}
      footer={footer}
      onClose={onCloseHandle}
    >
      {content}
    </CommonAlertModal>
  )
}

export const AlertConfirmModal = NiceModal.create(
  (props: AlertConfirmationModalProps) => {
    const modal = useModal()
    return (
      <AlertConfirmationModal
        closableBackDrop={false}
        {...props}
        open={modal.visible}
        onClose={() => {
          if (props.onClose) {
            props.onClose()
          }
          modal.hide()
        }}
        onConfirm={() => {
          if (props.onConfirm) {
            props.onConfirm()
          }
          modal.hide()
        }}
      />
    )
  },
)

export default AlertConfirmationModal
