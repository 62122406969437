import { Dayjs } from 'dayjs'

export enum ANNOUNCEMENT_TYPE {
  DEFAULT = 'NORMAL',
  ADVERTISE = 'ADVERTISE',
  EMERGENCY = 'EMERGENCY',
}
export interface AnnouncementRaw {
  announcement_code: string
  amount: unknown
  date_time: string
  icon: string
  inbox_type: string
  notification_type: string
  is_read: boolean
  message: string
  subject: string
  subtitle: string
}
export type AnnouncementItem = {
  code: string
  type: ANNOUNCEMENT_TYPE
  isRead: boolean
  content: string
  subtitle?: string
  amount?: number | null
  icon?: string
  date?: Dayjs
}
