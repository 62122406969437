import { AxiosRequestConfig } from 'axios'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { authStateCommon } from '@pig-common/recoils'
import { useAxios } from '@pig-common/hooks/useAxios'
import { walletStateCommon } from '@pig-common/recoils'
import { queryClient } from '@pig-casino/pages/_app.page'

export enum BALANCE_STATUS {
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export type BalanceRequest = {
  userUID: string
}

export type WalletResponse = BaseApiResponse<{
  user_code: string
  transaction_uid: string
  currency_code: string
  balance_amount: number
  coin_amount: number
}>

export type BalanceResponse = {
  Status: BALANCE_STATUS
  Message?: string
  CustomerCode?: string
  CustomerPhone?: string
  Balance?: string
  Coin?: string
  result?: any
}

export type UseBalanceHook = {
  callGetBalance: () => Promise<BalanceResponse>
}

interface IBalance {
  data: {
    balanceAmount: number
    coinAmount: number
  }
}

const updateBalance = (userUID, balance = 0, coin = 0) => {
  // TODO refactor useBalance to new version
  // 'USER_BALANCE' is from balanceApi.key.get; but should not import from apps/*
  queryClient.setQueryData(['USER_BALANCE', userUID], (oldData: IBalance) => ({
    ...oldData,
    data: {
      ...oldData?.data,
      balanceAmount: balance,
      coinAmount: coin,
    },
  }))
}

export const useBalance = (): UseBalanceHook => {
  const auth = useRecoilValue(authStateCommon.authState)
  const setWallet = useSetRecoilState(walletStateCommon.walletSelectorState)
  const { pigspinApiInstance } = useAxios()
  const requestWallet = (request: BalanceRequest): Promise<WalletResponse> => {
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/wallet/${request.userUID}/balance`,
      method: 'GET',
    }
    return fetchWithJSON<WalletResponse>(pigspinApiInstance, requestConfig)
  }

  const callGetBalance = async (): Promise<BalanceResponse> => {
    const walletResponse = await requestWallet({ userUID: auth.userUID || '' })
    const isSuccess = walletResponse.code === 200
    const balanceResponse = {
      Status: isSuccess ? BALANCE_STATUS.SUCCESS : BALANCE_STATUS.ERROR,
      Balance: isSuccess ? walletResponse.data.balance_amount.toFixed(2) : '',
      Coin: isSuccess ? walletResponse.data.coin_amount.toFixed(2) : '',
    }
    if (isSuccess) {
      const validateWallet = {
        balance: balanceResponse?.Balance,
        coin: balanceResponse?.Coin,
      }
      updateBalance(
        auth.userUID,
        parseFloat(balanceResponse?.Balance),
        parseFloat(balanceResponse?.Coin),
      )
      setWallet(validateWallet)
    }
    return balanceResponse
  }

  return {
    callGetBalance,
  }
}
