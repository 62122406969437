import { atom } from 'recoil'
import { KEYS } from '../keys'

export enum TOAST_TYPE {
  DEFAULT,
  SUCCESS = 'success',
  ERROR = 'error',
}
export interface ToastMessage {
  id: string
  message: string
  createdAt: string
  type: TOAST_TYPE
}

export type MiniToastState = ToastMessage[]

const miniToastAtom = atom<MiniToastState>({
  key: KEYS.MINI_TOAST,
  default: [],
})

export default miniToastAtom
