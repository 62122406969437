import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'

export interface IListParamsProps {
  keyPage: string
}
export interface IClaimParamsProps {
  uidPigHunt: string
  pageKey: string
}

async function list(params: IListParamsProps): Promise<CommonResponse<any>> {
  return await api.get(
    `/v1/loyalty/pighunt/event?host=PIG_BET&page=${params.keyPage}`,
  )
}
async function claim(params: IClaimParamsProps): Promise<CommonResponse<any>> {
  return await api.post('v1/loyalty/pighunt/submit-reward', {
    pighunt_schedule_uid: params.uidPigHunt,
    display_page: params.pageKey,
    host: 'PIG_BET',
  })
}

export const key = {
  list: 'LIST_PIGHUNT',
  claim: 'CLAIM_PIGHUNT',
}

export { list, claim }
