import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import isEmpty from 'lodash/isEmpty'
import { useBalance } from '@pig-common/hooks/useBalance'
import useAnnouncement from '@pig-common/hooks/useAnnouncement'
import { authStateCommon } from '@pig-common/recoils'
import usePigHunt, { PigHuntItem } from '@pig-common/hooks/usePigHunt'
import { numberWithCommas } from '@pig-common/utils/number'
import ModalSuccession from '../DailyQuests/modalSuccession'
import { ALERT_MODAL_TYPE } from '../Modal'
import PigTarget from './PigTarget'
import { useRecoilValue } from 'recoil'
import { TOAST_TYPE } from '@pig-common/recoils/modules/mini-toast'

const PigHuntContainer = styled.div`
  position: absolute;
  top: 64px;
  left: 0;
  right: 64px;
  height: 80%;
  z-index: 20;
  pointer-events: none;
`
const StyledModalSuccessionText = styled.div`
  font-size: 24px;
  h5 {
    display: block;
    color: var(--color-primary-1);
  }
`
const StyledModalSuccessionLink = styled.div`
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
`
const SuccessionModalImage = styled(Image)`
  object-fit: contain;
  transform: translateY(-60%);
`

const PigHunt = () => {
  const auth = useRecoilValue(authStateCommon.authState)
  const router = useRouter()
  const { callGetBalance } = useBalance()
  const { activedPigHunt, connectPigHunt, collectPigHunt } = usePigHunt({
    customerCode: auth.customerCode || '',
    cfid: auth.cfid || '',
  })
  const { addMiniToast } = useAnnouncement({
    customerCode: `${auth.customerCode}`,
  })
  const [successModal, setSuccessModal] = useState<JSX.Element | null>(null)
  const [successImage, setSuccessImage] = useState<string>('')
  const showingPig = useMemo(
    () => (activedPigHunt[0] ? activedPigHunt[0] : null),
    [activedPigHunt],
  )

  const gotoWallet = () => {
    setSuccessModal(null)
    router.push('/wallet')
  }
  const onClickPigHunt = async (data: PigHuntItem) => {
    const successionPigHunt = await collectPigHunt(data)
    if (successionPigHunt !== null && successionPigHunt.amount) {
      const htmlModal = (
        <>
          <h5>ยินดีด้วย!!</h5>
          {'คุณได้รับเงินสด '}
          <b>{numberWithCommas(successionPigHunt.amount)} บาท</b>
          <br />
          {'สามารถดูรายละเอียดที่ '}
          <StyledModalSuccessionLink onClick={gotoWallet}>
            กระเป๋าเงินสด
          </StyledModalSuccessionLink>
        </>
      )
      setSuccessModal(htmlModal)
      setSuccessImage(data.image)
      callGetBalance()
    } else {
      addMiniToast(
        successionPigHunt?.message || 'น่าเสียดาย~ มีผู้ที่พบหมูก่อนคุณ',
        TOAST_TYPE.ERROR,
      )
    }
  }
  const PigHuntModalSuccession = useCallback(() => {
    if (successModal) {
      return (
        <ModalSuccession
          active={!isEmpty(successModal)}
          errorCode={500}
          closeModalHandler={() => setSuccessModal(null)}
          claimQuestTicketContent={
            <StyledModalSuccessionText>
              {successModal}
            </StyledModalSuccessionText>
          }
          alertModalType={ALERT_MODAL_TYPE.UNEXPECTED_BEHAVIORS}
          messageError={<>ERROR...</>}
          customImage={
            <SuccessionModalImage
              className={classNames({ isCollected: 'collected' })}
              src={`/images/pig-hunt/${successImage}.png`}
              alt="pig-hunt"
              fill
            />
          }
        />
      )
    }
    return null
  }, [successModal, successImage])
  useEffect(() => {
    connectPigHunt()
  }, [])
  return (
    <PigHuntContainer>
      {showingPig && <PigTarget data={showingPig} onClick={onClickPigHunt} />}
      <PigHuntModalSuccession />
    </PigHuntContainer>
  )
}

export default PigHunt
