import { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Image from 'next/image'
import classNames from 'classnames'
import { PigHuntItem } from '@pig-common/hooks/usePigHunt'

export type Position = {
  x: number
  y: number
}
const animPigHuntEnter = keyframes`
  0% {
    opacity: 0;
  };
  100% {
    opacity: 1;
  }
`
const animPigHuntCollect = keyframes`
  0% {
    filter: saturate(1) brightness(1) saturate(1);
  };
  75% {
    filter: saturate(0.5) brightness(3) saturate(2);
    transform: scale(1.2);
  }
  100% {
    filter: saturate(0.5) brightness(3) saturate(2);
    transform: scale(0);
  }
`
const PigTargetContainer = styled.div<{
  x: number
  y: number
  isCollected: boolean
}>`
  position: absolute;
  top: ${({ x }) => `${x}%`};
  left: ${({ y }) => `${y}%`};
  width: 64px;
  height: 64px;
  border-radius: 100%;
  pointer-events: all;
  user-select: none;

  animation: ${({ isCollected }) =>
      isCollected ? animPigHuntCollect : animPigHuntEnter}
    0.3s forwards ease-out;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    filter: blur(40px);
    transform: scale(1.3);
    background: linear-gradient(90deg, #0fffc1, #7e0fff);
    background-size: 200% 200%;
    animation: animateGlow 10s ease infinite;

    @keyframes animateGlow {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }
  }
`
type PigHuntTargetProps = {
  data: PigHuntItem
  onClick: (data: PigHuntItem) => void
}
const PigTarget = ({ data, onClick }: PigHuntTargetProps) => {
  const [isCollected, setCollect] = useState<boolean>(false)
  const collectPigHunt = () => {
    setCollect(true)
    onClick(data)
  }
  useEffect(() => setCollect(false), [data])
  return (
    <PigTargetContainer
      x={data.location.x}
      y={data.location.y}
      onClick={() => collectPigHunt()}
      isCollected={isCollected}
    >
      <Image
        className={classNames({ isCollected: 'collected' })}
        src={`/images/pig-hunt/${data.image}.png`}
        alt="pig-hunt"
        fill
      />
    </PigTargetContainer>
  )
}

export default PigTarget
