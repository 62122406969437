import { AxiosRequestConfig } from 'axios'
import { BaseBoResponse } from '../service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { SystemStatusType } from '@pig-common/recoils/modules/system-status'
import { boApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { toCamelCase } from '@pig-common/utils/utils'

export interface ServerSideSystemStatus {
  UnderConstruction: boolean
}
export interface AnnouncementRaw {
  announcement_code: string
  amount: unknown
  date_time: string
  icon: string
  inbox_type: string
  notification_type: string
  is_read: boolean
  message: string
  subject: string
  subtitle: string
}

export const getSystemStatus =
  async (): Promise<ServerSideSystemStatus | null> => {
    try {
      const requestConfig: AxiosRequestConfig = {
        url: '/v1/feature-toggle/system-status',
        method: 'GET',
      }
      const response = await fetchWithJSON<
        BaseBoResponse<ServerSideSystemStatus>
      >(boApiInstance, requestConfig)
      const result: ServerSideSystemStatus = response.data
      return result
    } catch (error) {
      return null
    }
  }

export type AnnouncementRes = {
  isAuth: boolean
  systemStatus: SystemStatusType
  announcement: AnnouncementRaw[]
}

// NOTE: for v4.0 Tenant
export const getAnnouncement = async ({
  isAuth,
}: {
  isAuth: boolean
}): Promise<AnnouncementRes | null> => {
  try {
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/feature-toggle/system-status?product_type=PIG_BET',
      method: 'GET',
    }
    const response = await fetchWithJSON<BaseBoResponse<AnnouncementRaw>>(
      boApiInstance,
      requestConfig,
    )
    const result: AnnouncementRes = {
      isAuth,
      systemStatus: {
        ...response.data,
        toast: toCamelCase(response.data.Toast),
      },
      announcement:
        response.data.Inbox && Array.isArray(response.data.Inbox)
          ? [...response.data.Inbox]
          : [],
    }
    return result
  } catch (error) {
    return null
  }
}
