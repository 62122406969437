import React, { CSSProperties } from 'react'
import classNames from 'classnames'

import { Button, BUTTON_TYPE } from '../Button'
import { CloseOutlined } from '@ant-design/icons'

export type TermAndConditionProps = {
  title: string
  content: string
  className?: string
  style?: CSSProperties
  onClose: () => void
}

const TermAndCondition = ({
  title,
  content,
  className,
  style,
  onClose,
}: TermAndConditionProps): JSX.Element => {
  const handleClick = () => {
    onClose()
  }

  return (
    <div
      data-testid="tac__section"
      className={classNames('ps-tac', className)}
      style={style}
    >
      <div data-testid="tac__header" className="ps-tac__header">
        <strong data-testid="tac__title">{title}</strong>
        <Button
          id="tac__close"
          type={BUTTON_TYPE.PLAIN_TEXT}
          className="ms-auto p-0 ps-tac__close"
          onClick={handleClick}
        >
          <CloseOutlined />
          <span className="visually-hidden">Close</span>
        </Button>
      </div>
      <div data-testid="tac__line" className="ps-tac__line" />
      <div data-testid="tac__body" className="ps-tac__body">
        <div
          data-testid="tac__content"
          className="ps-tac__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  )
}

export default TermAndCondition
